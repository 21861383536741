<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="branch"></company-submenu>

      <div class="submenu-content">
        <div class="branches" v-for="block in item.body" :key="block">

              <img v-if="block.value.image" 
              :src="block.value.image.medium.src"
              :width="block.value.image.medium.width"
              :height="block.value.image.medium.height">

                <strong>{{ block.value.heading }}</strong>
              <p v-html="block.value.paragraph"></p>

        </div>
      </div>

  </div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from '../../utils/axios-api'
import { ref, onMounted } from 'vue'

export default {
  name: 'Branches',
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Our Branches", link: "Branches"})

    onMounted(() => { 
      getAPI
      .get('/content/pages/25')
      .then(response => {
        item.value = response.data
      })
    })

    return { item, breadcrumb_links }
  }
}
</script>

<style>
  .branches {
    font-family: Roboto;
    float: left;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  }
  .branches img {
    width: 215px;
    height: 145px;
  }
  .branches strong {
    font-size: 16px;
  }
  .branches:last-child {
    margin-bottom: 60px;
  }
  @media (max-width: 991px) {
    .branches {
      text-align: center;
    }
    .branches:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  @media (min-width: 992px) {
    .branches {
      width: 50%;
    }
    .branches:nth-child(-n+4) {
      height: 420px;
    }
  }
  @media (min-width: 1200px) {
    .branches {
      width: 33%;
    }
  }
  .branches p {
    margin: 5px 0;
  }
  .branches img { margin-bottom:10px;}
  .branches strong {display:block; margin-bottom:5px;}
</style>